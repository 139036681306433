import { render, staticRenderFns } from "./UIServerImage.vue?vue&type=template&id=b887cfe6&scoped=true&"
import script from "./UIServerImage.js?vue&type=script&lang=js&"
export * from "./UIServerImage.js?vue&type=script&lang=js&"
import style0 from "./UIServerImage.styl?vue&type=style&index=0&id=b887cfe6&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b887cfe6",
  null
  
)

export default component.exports