import { mapGetters } from 'vuex';

export default {
  name: 'UIServerImage',
  props: {
    message: Object,
  },
  data() {},
  computed: {
    ...mapGetters('theme', ['universalImage']),
  },
};
